<template>
  <div class="py-sm-10">
    <div class="row">
      <div class="mx-auto col-sm-12 col-md-10 col-lg-10 col-12">
        <v-card class="v-card--no-border v-card--no-bg mb-10">
          <h1 class="font-weight-bold text-center">
            {{ $t('Overview of personio companies') }}
          </h1>
        </v-card>
      </div>
    </div>

    <v-row class="mb-10 justify-center mx-auto">
      <v-col cols="12" md="3">
        <div class="personio-metric__card">
          <div class="personio-metric__card-icon">
            <img :src="imgPath('metric-icon1.svg')" alt="" />
          </div>
          <div class="personio-metric__card-data">
            <div class="personio-metric__card-title">Companies connected</div>
            <div class="personio-metric__card-value">{{ personioMetrics.totalCompanies }}</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="personio-metric__card">
          <div class="personio-metric__card-icon">
            <img :src="imgPath('metric-icon2.svg')" alt="" />
          </div>
          <div class="personio-metric__card-data">
            <div class="personio-metric__card-title">Active companies</div>
            <div class="personio-metric__card-value">{{ personioMetrics.syncActivated }}</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="personio-metric__card">
          <div class="personio-metric__card-icon">
            <img :src="imgPath('metric-icon3.svg')" alt="" />
          </div>
          <div class="personio-metric__card-data">
            <div class="personio-metric__card-title">Syncs of last 24 hours</div>
            <div class="personio-metric__card-value">{{ personioMetrics.successfulSync }}</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <div>
          <div class="pb-5">
            <div class="personio-companies__table mb-5">
              <v-card class="my-bav__table-card" :class="{ 'v-card--no-border': $vuetify.breakpoint.smAndDown }">
                <v-card-text
                  class="border-top border-left border-right"
                  :class="[{ 'border--dark': !$vuetify.breakpoint.smAndDown }]"
                >
                  <div class="text--primary personio-companies__table-heading">{{ $t('Search & filter') }}</div>
                </v-card-text>
                <v-card-text
                  class="d-flex align-center flex-wrap pb-0 border-left border-right"
                  :class="[
                    { 'px-2': $vuetify.breakpoint.smAndDown },
                    { 'mb-2': $vuetify.breakpoint.smAndDown },
                    { 'border-bottom': $vuetify.breakpoint.smAndDown },
                    { 'border--dark': !$vuetify.breakpoint.smAndDown },
                  ]"
                >
                  <v-row class="px-3">
                    <v-col cols="12" md="3" sm="12" :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }">
                      <v-text-field
                        v-model="searchTerm"
                        single-line
                        dense
                        outlined
                        hide-details
                        clearable
                        :append-icon="icons.mdiMagnify"
                        :label="$t('Search')"
                        :placeholder="$t('Search')"
                        :class="{ 'mb-5 my-bav__table-filter': !$vuetify.breakpoint.smAndDown }"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="1"></v-col>
                    <v-col cols="12" md="4" sm="12" class="d-flex justify-end">
                      <!--<v-select-->
                      <!--:items="[]"-->
                      <!--single-line-->
                      <!--outlined-->
                      <!--dense-->
                      <!--hide-details-->
                      <!--clearable-->
                      <!--:placeholder="$t('Filter by company')"-->
                      <!--:class="{'mb-5 pr-0 my-bav__table-filter': !$vuetify.breakpoint.smAndDown}"-->
                      <!--&gt;</v-select>-->
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                      class="d-flex justify-end"
                      :class="{ 'px-0': !$vuetify.breakpoint.smAndDown }"
                    >
                      <v-select
                        v-model="filterBySyncStatus"
                        :items="[
                          { text: 'Autosync activated', value: true },
                          { text: 'Autosync deactivated', value: 'sync=false&schedule=true' },
                          { text: 'Autosync not setup yet', value: 'schedule=false&draft=false' },
                          { text: 'Incomplete setup', value: 'draft=true' },
                        ]"
                        single-line
                        outlined
                        dense
                        clearable
                        hide-details
                        placeholder="Filter by sync status"
                        class="mb-5"
                        :class="{ 'my-bav__table-filter': !$vuetify.breakpoint.smAndDown }"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text
                  class="d-flex justify-space-between flex-wrap py-4"
                  :class="[
                    { 'mb-2': $vuetify.breakpoint.smAndDown },
                    { 'border--dark': !$vuetify.breakpoint.smAndDown },
                    { 'border-right': !$vuetify.breakpoint.smAndDown },
                    { 'border-left': !$vuetify.breakpoint.smAndDown },
                    { 'border-top': !$vuetify.breakpoint.smAndDown },
                  ]"
                >
                  <span class="py-4"> </span>
                  <v-btn
                    color="primary"
                    class="flex-button personio-action-btn"
                    @click="$router.push('/personio-connect')"
                    >Connect new company to personio</v-btn
                  >
                </v-card-text>

                <v-data-table
                  :mobile-breakpoint="0"
                  :headers="headers"
                  :items="loadedCompanies"
                  :server-items-length="totalCompanies"
                  :loading="loadingCompanies"
                  :footer-props="{
                    'items-per-page-options': [15, 30, 50, 100],
                    'items-per-page-text': $t('Rows per page:'),
                  }"
                  class="text-no-wrap cursor-pointer"
                  @update:items-per-page="setLimit"
                  @click:row="handleRowClick"
                  @pagination="handlePagination"
                >
                  <template #[`header.trending`]>
                    <v-icon size="22">
                      {{ icons.mdiTrendingUp }}
                    </v-icon>
                  </template>

                  <template #[`item.name`]="{ item }">
                    <span class="text-no-wrap th">{{ item.name }}</span>
                  </template>

                  <template #[`item.portalCompanyId`]="{ item }">
                    <div class="d-flex align-items-center">
                      <div class="th mw-200" :title="item.portalCompanyId">
                        {{ item.portalCompanyId.slice(0, 18) }}...
                      </div>
                      <v-btn icon small @click="copy(item.portalCompanyId)">
                        <v-icon size="18">
                          {{ icons.mdiContentCopy }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template #[`item.hr`]="{ item }">
                    <a
                      v-for="user in item.hr"
                      href="#"
                      style="text-decoration: none"
                      class="text-no-wrap th"
                      @click.stop.prevent="$router.push('/profile/' + user.id)"
                    >
                      {{ user.firstName }} {{ user.lastName }}<br />
                    </a>
                  </template>

                  <template #[`item.employees`]="{ item }">
                    <span v-if="item.schedule" class="text-no-wrap th">{{ item.employees }}</span>
                    <span v-else class="text-no-wrap th">-</span>
                  </template>

                  <template #[`item.autosync`]="{ item }">
                    <div v-if="!item.draft" class="personio-sync-status">
                      <v-switch
                        v-if="item.schedule && item.schedule.length"
                        v-model="item.autosync"
                        class="my-0"
                        inset
                        hide-details
                        @change="changeAutoSyncStatus(item.portalCompanyId, item.autosync)"
                      ></v-switch>
                      <span v-else class="text--disabled">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-switch class="my-0" inset disabled hide-details></v-switch>
                            </span>
                          </template>
                          <span>{{ $t('No schedule created to start to autosync') }}</span>
                        </v-tooltip>
                      </span>
                    </div>
                    <div v-else class="personio-sync-status text--disabled">
                      <span>
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-switch
                                class="my-0 personio-sync-status__draft"
                                inset
                                disabled
                                color="red"
                                hide-details
                              ></v-switch>
                            </span>
                          </template>
                          <span>{{ $t('Autosync not possible due to incomplete setup') }}</span>
                        </v-tooltip>
                      </span>
                    </div>
                  </template>

                  <template #[`item.actions`]="{ item }">
                    <div v-if="!item.draft" class="d-flex align-center justify-center">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="$router.push(`personio-companies/${item.portalCompanyId}/?edit=false`)"
                          >
                            <v-icon size="18" color="primary">
                              {{ icons.mdiEye }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('View') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            v-bind="attrs"
                            @click.stop="$router.push(`personio-companies/${item.portalCompanyId}/?edit=true`)"
                            v-on="on"
                          >
                            <v-icon size="18" color="primary">
                              {{ icons.mdiPencilOutline }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('edit') }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else class="d-flex align-center justify-center">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            small
                            v-bind="attrs"
                            @click="$router.push(`personio-connect/?company=${item.portalCompanyId}&step=${item.step}`)"
                            v-on="on"
                          >
                            <v-icon size="18" color="primary">
                              {{ icons.mdiStepForward }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('Continue setup') }}</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template slot="no-data">
                    <div class="my-5">
                      <p>{{ $t('myBavNoResults') }}</p>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch } from '@vue/composition-api';
import { mdiMagnify, mdiContentCopy, mdiPencilOutline, mdiEye, mdiAlertCircleOutline, mdiStepForward } from '@mdi/js';
import { getPersonioCompaniesList, getPersonioMetrics, setAutosyncStatus } from '../../api/personio-connector';

export default {
  name: 'GlobalAdminPersonioCompanies',

  setup() {
    const vm = getCurrentInstance().proxy;

    const loadedCompanies = ref([]);
    const totalCompanies = ref(0);
    const loadingCompanies = ref(false);
    const filterBySyncStatus = ref(null);

    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref(null);
    const searchTerm = ref('');

    const personioMetrics = ref({
      successfulSync: 0,
      syncActivated: 0,
      totalCompanies: 0,
    });

    const headers = computed(() => {
      return [
        {
          text: vm.$t('tableHeaderCompany'),
          value: 'name',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('COMPANY ID'),
          value: 'portalCompanyId',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('HR ADMIN'),
          value: 'hr',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('SYNCED EMPLOYEES'),
          value: 'employees',
          sortable: false,
          align: 'right',
          class: 'text-uppercase',
        },
        {
          text: vm.$t('SYNC STATUS'),
          value: 'autosync',
          sortable: false,
          align: 'center',
          class: 'text-uppercase',
        },
        {
          text: vm.$t('tableHeaderActions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const fetchAll = () => {
      loadingCompanies.value = true;
      getPersonioMetrics().then((res) => {
        personioMetrics.value = res.data;
      });
      getPersonioCompaniesList(offset.value, limit.value, searchTerm.value, filterBySyncStatus.value).then((res) => {
        loadedCompanies.value = res.data.page;
        loadingCompanies.value = false;
        totalCompanies.value = res.data.total;
      });
    };

    const changeAutoSyncStatus = (companyId, status) => {
      setAutosyncStatus(companyId, status).then(() => {
        fetchAll();
      });
    };

    const copy = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        vm.$store.commit('showMessage', {
          text: 'Copied',
          color: '#4caf50',
          timeout: '1000',
        });
      });
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const imgPath = (filename) => {
      return require(`@/assets/static/images/${filename}`);
    };

    const handleRowClick = (row) => {
      if (!row.draft) {
        vm.$router.push(`personio-companies/${row.company.id}`);
      } else {
        vm.$router.push(`personio-connect/?company=${row.company.id}&step=${row.step}`);
      }
    };

    watch(searchTerm, () => {
      fetchAll();
    });

    watch(filterBySyncStatus, () => {
      fetchAll();
    });

    fetchAll();

    return {
      handleRowClick,
      loadedCompanies,
      totalCompanies,
      loadingCompanies,
      headers,
      searchQuery,
      filterBySyncStatus,
      setLimit,
      handlePagination,
      imgPath,
      searchTerm,
      personioMetrics,
      copy,
      changeAutoSyncStatus,

      icons: {
        mdiMagnify,
        mdiContentCopy,
        mdiPencilOutline,
        mdiEye,
        mdiAlertCircleOutline,
        mdiStepForward,
      },
    };
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}

.personio-metric {
  &__card {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 26px 16px;
    &-icon {
      background: rgba(40, 48, 206, 0.25);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 22px;
        height: 22px;
      }
    }
    &-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #505a68;
      margin-bottom: 5px;
    }
    &-value {
      font-weight: 700;
      font-size: 19px;
      line-height: 23px;
      color: #505a68;
    }
  }
}

.mw-100 {
  max-width: 110px;
  min-width: 110px;
}
.mw-200 {
  max-width: 160px;
  min-width: 160px;
}

.personio-action-btn {
  min-width: 320px !important;
  border-radius: 3px;
}

.personio-sync-status {
  display: flex;
  align-items: center;
  justify-content: center;
  &__draft {
    .v-input--switch__track {
      color: #ff0505;
      background: #ff0505;
      opacity: 1 !important;
    }
  }
}

.personio-companies {
  &__table {
    .v-select__selection {
      color: #2830ce !important;
    }
    .v-data-table__checkbox {
      .v-icon__svg {
        color: #2830ce !important;
      }
    }
    .v-data-footer {
      color: #2830ce !important;
      border-color: rgba(40, 48, 206, 0.25) !important;
      button.v-btn--disabled {
        opacity: 0.4;
      }
      svg {
        fill: #2830ce;
      }
    }
    th {
      background: rgba(40, 48, 206, 0.25) !important;
      border-top: 1px solid rgba(40, 48, 206, 0.15) !important;
      border-color: #2830ce !important;
      border-bottom: none !important;
      span {
        color: #2830ce !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
      }
    }
    tr,
    td {
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-card {
      border-radius: 3px !important;
      margin-top: 31px;
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #505a68;
    }
    &-filter {
      /*max-width: 320px;*/
      min-width: 320px;
    }
    &-info {
      padding: 10px 8px;
      border: 1px solid #2830ce;
      border-radius: 3px;
      margin: 8px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /*transform: translateX(50%) translateY(10px);*/
      color: #505a68;

      ul {
        padding: 0;
        padding-left: 12px;
      }
      &-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #2830ce;
        margin-bottom: 3px;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .hidden-input-container {
    background: #ffffff;
    border: 1px solid #2830ce;
    border-radius: 3px;
    height: 170px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .personio-companies {
    &__table {
      table {
        border: 1px solid rgba(40, 48, 206, 0.15) !important;
      }
    }
    .v-data-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .v-data-footer__icons-after,
    .v-data-footer__icons-before {
      max-width: 16px;
      max-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-data-footer__select {
      min-width: unset !important;
    }
    .v-data-footer__pagination {
      margin: 0 10px;
    }
  }
}
</style>
